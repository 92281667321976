import React from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import LoadingButton from '@mui/lab/LoadingButton'
import Stack from '@mui/material/Stack'
import Fields, { Field, useFieldsAPI } from '@oi/react/components/fields'
import { LogoColorIcon } from '@oi/react/components/icons'
import { useDebtorLoginMutation } from '@oi/react/modules/api'
import { LanguageSwitcher } from '@oi/react/modules/language'

import type { LoginWithPin } from '@oi/react/modules/api'

export default function LoginRoute() {
  const fieldsAPI = useFieldsAPI<LoginWithPin>()
  const navigate = useNavigate()
  const intl = useIntl()

  const [login, loginMutation] = useDebtorLoginMutation()

  const handleLoginClick = React.useCallback(async () => {
    const { isValid, values } = await fieldsAPI.getValues()

    if (isValid && values) {
      const { error, data } = await login(values)

      if (!error) {
        navigate(`/cases/${data}`)
      }
    }

  }, [fieldsAPI, login, navigate])

  return (
    <Stack
      alignItems={'center'}
      display={'flex'}
      flexGrow={1}
      gap={6}
      justifyContent={'center'}
      minHeight={'100vh'}>
      <Stack
        gap={2}
        maxWidth={500}
        p={3}
        width={'100%'}>
        <Stack mb={2}>
          <LogoColorIcon />
        </Stack>

        <Fields
          api={fieldsAPI}
          error={loginMutation.error}
          onEnter={handleLoginClick}
          errorMessages={defineMessages({
            'api.unknown-combination': {
              id: 'api.unknown-combination',
              defaultMessage: 'Unknown combination!'
            }
          })}>
          <Field.Text
            autoComplete={'off'}
            name={'identificationNumber'}
            label={intl.formatMessage({
              id: 'routes.login.code',
              defaultMessage: 'Access code'
            })}
            required />

          <Field.Text
            autoComplete={'off'}
            name={'identificationPin'}
            type={'password'}
            label={intl.formatMessage({
              id: 'routes.login.pin',
              defaultMessage: 'Pincode'
            })}
            required />
        </Fields>

        <Stack
          alignItems={'center'}
          direction={'row'}
          display={'flex'}
          justifyContent={'space-between'}
          mt={2}>
          <LanguageSwitcher />

          <LoadingButton
            loading={loginMutation.loading}
            onClick={handleLoginClick}
            variant={'contained'}>
            <FormattedMessage
              defaultMessage={'Login'}
              id={'btn.login'} />
          </LoadingButton>
        </Stack>
      </Stack>
    </Stack>
  )
}
