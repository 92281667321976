import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import Typography from '@mui/material/Typography'
import { apiGlobalErrorMessage } from '@oi/react/modules/api'

import type { ApiError } from '@oi/react/modules/api'

export interface FieldErrorProps {
  error?: ApiError | string
  errorMessages?: {
    [key: string]: {
      id: string
      defaultMessage: string
    }
  }
}

export default function FieldError({
  error,
  errorMessages = {}
}: FieldErrorProps) {
  const formContext = useFormContext()

  const [globalError, setGlobalError] = React.useState<string | null>(null)
  const intl = useIntl()

  React.useEffect(() => {
    let errorMessage: string | null = null

    if (error) {
      const errorCode = typeof error === 'string'
        ? error
        : error.code

      if (errorCode in errorMessages) {
        errorMessage = intl.formatMessage(errorMessages[errorCode])

      } else if (errorCode === 'api.validation' && typeof error !== 'string' && 'fields' in error) {
        const fieldErrors = error.fields as { [key: string]: string }

        Object.keys(fieldErrors).forEach((fieldName) => {
          let apiErrorMessage = fieldErrors[fieldName]
          if (apiErrorMessage in errorMessages) {
            apiErrorMessage = intl.formatMessage(errorMessages[apiErrorMessage])
          }

          formContext.setError(fieldName, {
            message: apiErrorMessage
          })
        })

      } else {
        console.warn(`Following message is missing "${errorCode}" showing global as fallback`)
        errorMessage = intl.formatMessage(apiGlobalErrorMessage)
      }

      if (errorMessage) {
        setGlobalError(errorMessage)
      }
    }
  }, [error, errorMessages, formContext, intl])

  if (!globalError) {
    return null
  }

  return (
    <Typography
      color={'error'}
      variant={'subtitle2'}>
      {globalError}
    </Typography>
  )
}
