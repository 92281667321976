import {
  addMessagesToCache,
  getDateMessages,
  Language,
  LoadMessagesResponse
} from '@oi/react/modules/language'

export function fetchTranslations(languageCode: string): Promise<LoadMessagesResponse['messages']> {
  switch (languageCode) {
    case 'nl-NL':
      return import('../translations/nl-NL.json') as never

    default:
      return import('../translations/en-GB.json') as never
  }
}

export async function loadMessages(language: Language) {
  const dateMessages = await getDateMessages(language)
  const messages = await fetchTranslations(language)

  addMessagesToCache(language, messages, dateMessages)
}
