import React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { EarthLineIcon } from '@oi/react/components/icons'

import { useLanguageProvider } from './language.hooks'

export default function LanguageSwitcher() {
  const languageProvider = useLanguageProvider()
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLButtonElement>(null)

  return (
    <>
      <Button
        color={'secondary'}
        onClick={event => setMenuAnchor(event.currentTarget)}
        size={'small'}
        startIcon={<EarthLineIcon />}>
        {languageProvider.activeLocale.split('-').shift()?.toUpperCase()}
      </Button>

      <Menu
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        open={Boolean(menuAnchor)}>
        {languageProvider.supportedLocales.map((locale) => (
          <MenuItem
            key={locale.code}
            onClick={() => languageProvider.switchLanguage(locale.code)}>
            {locale.language}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

