import React from 'react'

import { Language } from './language.interfaces'

export const LanguageContext = React.createContext({})

export interface SupportedLocal {
  code: string
  language: string
}

export interface LanguageProvider {
  switchLanguage: (code: string) => void
  supportedLocales: SupportedLocal[]
  activeLocale: Language
}

export function useLanguageProvider() {
  return React.useContext(LanguageContext) as never as LanguageProvider
}
