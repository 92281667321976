import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import { ReactComponent as ArrowDownLineSvg } from 'remixicon/icons/Arrows/arrow-down-line.svg'
import { ReactComponent as ArrowDownSLineSvg } from 'remixicon/icons/Arrows/arrow-down-s-line.svg'
import { ReactComponent as ArrowRightSLineSvg } from 'remixicon/icons/Arrows/arrow-right-s-line.svg'
import { ReactComponent as ArrowUpLineSvg } from 'remixicon/icons/Arrows/arrow-up-line.svg'
import { ReactComponent as ArrowUpSLineSvg } from 'remixicon/icons/Arrows/arrow-up-s-line.svg'
import { ReactComponent as Building4LineSvg } from 'remixicon/icons/Buildings/building-4-line.svg'
import { ReactComponent as CalendarLineSvg } from 'remixicon/icons/Business/calendar-line.svg'
import { ReactComponent as MailSendLineSvg } from 'remixicon/icons/Business/mail-send-line.svg'
import { ReactComponent as StackLineSvg } from 'remixicon/icons/Business/stack-line.svg'
import { ReactComponent as WindowLineSvg } from 'remixicon/icons/Business/window-line.svg'
import { ReactComponent as Chat2FillSvg } from 'remixicon/icons/Communication/chat-2-fill.svg'
import { ReactComponent as Edit2FillSvg } from 'remixicon/icons/Design/edit-2-fill.svg'
import { ReactComponent as FocusLineSvg } from 'remixicon/icons/Design/focus-line.svg'
import { ReactComponent as SmartphoneLineSvg } from 'remixicon/icons/Device/smartphone-line.svg'
import { ReactComponent as FileDownloadFillSvg } from 'remixicon/icons/Document/file-download-fill.svg'
import { ReactComponent as FileDownloadLineSvg } from 'remixicon/icons/Document/file-download-line.svg'
import { ReactComponent as FileListLineSvg } from 'remixicon/icons/Document/file-list-3-line.svg'
import { ReactComponent as MoneyEuroCircleLineSvg } from 'remixicon/icons/Finance/money-euro-circle-line.svg'
import { ReactComponent as EarthLineSvg } from 'remixicon/icons/Map/earth-line.svg'
import { ReactComponent as PauseLineSvg } from 'remixicon/icons/Media/pause-line.svg'
import { ReactComponent as PlayCircleFillSvg } from 'remixicon/icons/Media/play-circle-fill.svg'
import { ReactComponent as PlayFillSvg } from 'remixicon/icons/Media/play-fill.svg'
import { ReactComponent as AddLineSvg } from 'remixicon/icons/System/add-line.svg'
import { ReactComponent as CheckLineSvg } from 'remixicon/icons/System/check-line.svg'
import { ReactComponent as CheckboxBlankLineSvg } from 'remixicon/icons/System/checkbox-blank-line.svg'
import { ReactComponent as CheckboxFillSvg } from 'remixicon/icons/System/checkbox-fill.svg'
import { ReactComponent as CheckboxIndeterminateFillSvg } from 'remixicon/icons/System/checkbox-indeterminate-fill.svg'
import { ReactComponent as CheckboxIndeterminateLineSvg } from 'remixicon/icons/System/checkbox-indeterminate-line.svg'
import { ReactComponent as CheckboxLineSvg } from 'remixicon/icons/System/checkbox-line.svg'
import { ReactComponent as CloseLineSvg } from 'remixicon/icons/System/close-line.svg'
import { ReactComponent as DeleteBinFillSvg } from 'remixicon/icons/System/delete-bin-fill.svg'
import { ReactComponent as EyeLineSvg } from 'remixicon/icons/System/eye-line.svg'
import { ReactComponent as InformationFillSvg } from 'remixicon/icons/System/information-fill.svg'
import { ReactComponent as LogoutCircleRLineSvg } from 'remixicon/icons/System/logout-circle-r-line.svg'
import { ReactComponent as LoopLeftLineSvg } from 'remixicon/icons/System/loop-left-line.svg'
import { ReactComponent as MenuLineSvg } from 'remixicon/icons/System/menu-line.svg'
import { ReactComponent as SearchLineSvg } from 'remixicon/icons/System/search-line.svg'
import { ReactComponent as User3LineSvg } from 'remixicon/icons/User & Faces/user-3-line.svg'

function wrapRemixIcon(component: React.ElementType) {
  return React.forwardRef((props: SvgIconProps, ref) => {
    return (
      <SvgIcon
        ref={ref}
        component={component}
        fontSize={'inherit'}
        {...props}
      />
    )
  })
}

export const ArrowDownSLineIcon = wrapRemixIcon(ArrowDownSLineSvg)
export const ArrowUpLineIcon = wrapRemixIcon(ArrowUpLineSvg)
export const ArrowDownLineIcon = wrapRemixIcon(ArrowDownLineSvg)
export const ArrowRightSLineIcon = wrapRemixIcon(ArrowRightSLineSvg)
export const ArrowUpSLineIcon = wrapRemixIcon(ArrowUpSLineSvg)
export const Building4LineIcon = wrapRemixIcon(Building4LineSvg)
export const CheckboxBlankLineIcon = wrapRemixIcon(CheckboxBlankLineSvg)
export const CheckboxIndeterminateLineIcon = wrapRemixIcon(CheckboxIndeterminateLineSvg)
export const CheckboxFillIcon = wrapRemixIcon(CheckboxFillSvg)
export const CheckboxIndeterminateFillIcon = wrapRemixIcon(CheckboxIndeterminateFillSvg)
export const CheckboxLineIcon = wrapRemixIcon(CheckboxLineSvg)
export const CheckLineIcon = wrapRemixIcon(CheckLineSvg)
export const CloseLineIcon = wrapRemixIcon(CloseLineSvg)
export const InformationFillIcon = wrapRemixIcon(InformationFillSvg)
export const FileListLineIcon = wrapRemixIcon(FileListLineSvg)
export const FocusLineIcon = wrapRemixIcon(FocusLineSvg)
export const LogoutCircleRLineIcon = wrapRemixIcon(LogoutCircleRLineSvg)
export const MenuLineIcon = wrapRemixIcon(MenuLineSvg)
export const PlayCircleFillIcon = wrapRemixIcon(PlayCircleFillSvg)
export const StackLineIcon = wrapRemixIcon(StackLineSvg)
export const User3LineIcon = wrapRemixIcon(User3LineSvg)
export const WindowLineIcon = wrapRemixIcon(WindowLineSvg)
export const AddLineIcon = wrapRemixIcon(AddLineSvg)
export const SearchLineIcon = wrapRemixIcon(SearchLineSvg)
export const DeleteBinFillIcon = wrapRemixIcon(DeleteBinFillSvg)
export const CalendarLineIcon = wrapRemixIcon(CalendarLineSvg)
export const Edit2FillIcon = wrapRemixIcon(Edit2FillSvg)
export const PauseLineIcon = wrapRemixIcon(PauseLineSvg)
export const FileDownloadFillIcon = wrapRemixIcon(FileDownloadFillSvg)
export const PlayFillIcon = wrapRemixIcon(PlayFillSvg)
export const Chat2FillIcon = wrapRemixIcon(Chat2FillSvg)
export const MoneyEuroCircleLineIcon = wrapRemixIcon(MoneyEuroCircleLineSvg)
export const LoopLeftLineIcon = wrapRemixIcon(LoopLeftLineSvg)
export const MailSendLineIcon = wrapRemixIcon(MailSendLineSvg)
export const SmartphoneLineIcon = wrapRemixIcon(SmartphoneLineSvg)
export const FileDownloadLineIcon = wrapRemixIcon(FileDownloadLineSvg)
export const EarthLineIcon = wrapRemixIcon(EarthLineSvg)
export const EyeLineIcon = wrapRemixIcon(EyeLineSvg)
