import React from 'react'
import { FieldPath, FieldValues, get, useController, useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import Button, { buttonClasses } from '@mui/material/Button'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import OutlinedInput from '@mui/material/OutlinedInput'
import { styled } from '@mui/material/styles'
import { maxFileSize } from '@oi/constants/file'

import type { BaseField } from '../fields.interface'

import { useFieldName, useFieldsController } from '../fields.hooks'
import { useFieldValidate } from '../validations/field-validate.hook'
import FieldBase from './field.base.component'

export interface FieldFileProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends BaseField<TFieldValues, TName> {
  // When set, use this field name for the default value
  defaultValueName?: TName
  accept?: string
}

const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  paddingLeft: 0,
  cursor: 'pointer',

  [`& .${buttonClasses.root}`]: {
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(0.5),
    height: theme.spacing(4),
    minWidth: 'fit-content',
    zIndex: 1,

    backgroundColor: theme.palette.secondary.dark
  },

  [`& .${outlinedInputClasses.input}`]: {
    cursor: 'pointer'
  }
}))

export default function FieldFile<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  label,
  labelVariant,
  hint,
  required,
  defaultValue,
  disabled,
  hidden,
  validations,
  // placeholder,
  // autoComplete,
  // autoFocus,
  skeleton,
  // readOnly,
  // onBlur,
  onClick,

  defaultValueName,
  accept
}: FieldFileProps<TFieldValues, TName>) {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null)
  const controller = useFieldsController()
  const fieldName = useFieldName<TName>(name)
  const defaultValueFieldName = useFieldName<TName>(defaultValueName)

  const validate = useFieldValidate(validations)

  const form = useFormContext()
  const { field, fieldState, formState } = useController<TFieldValues, TName>({
    name: fieldName,
    rules: {
      required,
      validate
    },
    defaultValue,
    disabled
  })

  return (
    <FieldBase
      error={fieldState.error}
      hidden={hidden}
      hint={hint}
      label={label}
      labelVariant={labelVariant}
      name={field.name}
      onClick={onClick}
      required={required}
      skeleton={skeleton}>
      <input
        ref={hiddenFileInput}
        accept={accept}
        style={{ display: 'none' }}
        type={'file'}
        onChange={async (event) => {
          if (event.target.files && event.target.files?.length > 0) {
            const fileUploaded = event.target.files[0]

            if (fileUploaded && fileUploaded.size > maxFileSize) {
              form.setError(fieldName, { type: 'field-error.file.to-big.5mb' })

            } else {
              form.clearErrors(fieldName)
              field.onChange(fileUploaded)
            }
          }
        }}
      />

      <StyledOutlinedInput
        value={field.value?.name || (defaultValueName ? get(formState.defaultValues, defaultValueFieldName) : '') || ''}
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()

          hiddenFileInput.current?.click()
        }}
        startAdornment={(
          <Button
            color={'secondary'}
            disabled={controller.disabled || disabled}
            size={'small'}
            variant={'contained'}
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()

              hiddenFileInput.current?.click()
            }}>
            <FormattedMessage
              defaultMessage={'Select file'}
              id={'btn.select-file'} />
          </Button>
        )}
        fullWidth
      />
    </FieldBase>
  )
}
