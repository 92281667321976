import React from 'react'
import { useLocation } from 'react-router'
import { Outlet } from 'react-router-dom'
import { posthog } from 'posthog-js'

export default function PageView() {
  const location = useLocation()
  const lastTrackedUrl = React.useRef('')

  React.useEffect(() => {
    // Prevents sending the initial pageview twice
    if (lastTrackedUrl.current !== window.location.href) {
      posthog.capture('$pageview', {
        $current_url: window.location.href
      })

      lastTrackedUrl.current = window.location.href
    }

  }, [location])

  return <Outlet />
}
