import { useQuery } from '@tanstack/react-query'

import type {
  CaseTimelineStep,
  DebtorApiCreditCaseMessageCreateOneRequest,
  LoginWithPin
} from './open-api'

import { debtorApi } from './api.constants'
import { getCreditCaseQueryKey } from './api.credit-case'
import { unwrapResponse, useWrapMethod } from './api.internal'

export function useDebtorLoginMutation() {
  return useWrapMethod<
    LoginWithPin,
    string
  >((loginWithPin) => debtorApi.loginWithPin({ loginWithPin }))
}

export function useDebtorCreditCase(hash: string) {
  return useQuery({
    queryKey: getCreditCaseQueryKey(hash),
    queryFn: () => unwrapResponse(debtorApi.creditCasesFindByHash({ hash }))
  })
}

export function useDebtorCaseMessageMutation() {
  return useWrapMethod<
    DebtorApiCreditCaseMessageCreateOneRequest,
    CaseTimelineStep
  >(
    (params: DebtorApiCreditCaseMessageCreateOneRequest) => debtorApi.creditCaseMessageCreateOne(params)
  )
}
