import React from 'react'
import { FieldPath, FieldValues, useController } from 'react-hook-form'
import Chip from '@mui/material/Chip'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectProps } from '@mui/material/Select'
import Stack from '@mui/material/Stack'

import type { BaseField } from '../fields.interface'

import { useFieldName, useFieldsController } from '../fields.hooks'
import { useFieldValidate } from '../validations/field-validate.hook'
import FieldBase from './field.base.component'
import { fieldClasses } from './field.classes'

export interface FieldSelectProps<
  Item,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends BaseField<TFieldValues, TName> {
  openPosition?: 'top-left' | 'top-right'
  multiple?: boolean
  options: Item[]
  labelProp?: keyof Item
  valueProp?: keyof Item
  renderOptionLabel?: (option: Item | object | string, fieldName: string) => React.ReactNode | string
  color?: SelectProps['color']
}

export default function FieldSelect<
  Item,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  label,
  labelVariant,
  hint,
  required,
  defaultValue,
  disabled,
  hidden,
  validations,
  placeholder,
  autoComplete,
  autoFocus,
  skeleton,
  readOnly,
  onBlur,
  onClick,
  className,

  openPosition = 'top-left',
  multiple,
  options,
  renderOptionLabel,
  valueProp = 'value' as keyof Item,
  labelProp = 'label' as keyof Item,
  color
}: FieldSelectProps<Item, TFieldValues, TName>) {
  const controller = useFieldsController()
  const fieldName = useFieldName<TName>(name)

  const validate = useFieldValidate(validations)
  const fallbackOptions = React.useRef([])

  const { field, fieldState } = useController<TFieldValues, TName>({
    name: fieldName,
    rules: {
      required,
      validate
    },
    defaultValue
  })

  const handleRenderOptionLabel = React.useCallback((option: Item): React.ReactNode | string => {
    if (renderOptionLabel) {
      return renderOptionLabel(option, fieldName)
    }

    return option[labelProp] as string
  }, [renderOptionLabel, labelProp, fieldName])

  const renderChipLabel = React.useCallback((value: string | number): React.ReactNode | string => {
    const option = options.find((option) => option[valueProp] === value)

    if (option) {
      return handleRenderOptionLabel(option)
    }

    // Get the option from the fallbacks if its defined
    const fallbackOption = fallbackOptions.current?.find((option) => option[valueProp] === value)
    if (fallbackOption) {
      return handleRenderOptionLabel(fallbackOption)

    } else if (typeof value === 'object') {
      return handleRenderOptionLabel(value)
    }

    return value
  }, [options, valueProp, handleRenderOptionLabel])

  const renderValue = React.useCallback((selected: string[] | string, isSummaryRender?: boolean) => {
    if (typeof selected === 'string' || selected.length === 0) {
      return null
    }

    return (
      <Stack
        sx={{
          mt: isSummaryRender ? 1 : 0,
          display: 'flex',
          flexWrap: 'wrap',
          gap: 0.4
        }}>
        {selected.map((option) => (
          <Chip
            key={option}
            label={renderChipLabel(option)}
            variant={'outlined'} />
        ))}
      </Stack>
    )
  }, [renderChipLabel])

  return (
    <FieldBase
      className={className}
      disabled={disabled || controller.disabled}
      error={fieldState.error}
      hidden={hidden}
      hint={hint}
      label={label}
      labelVariant={labelVariant}
      name={field.name}
      onClick={onClick}
      required={required}
      skeleton={skeleton}>
      <Select
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        className={fieldClasses.field}
        color={color}
        data-test-id={`field.${field.name}`}
        disabled={disabled || controller.disabled}
        error={!!fieldState.error}
        id={`field.${field.name}`}
        inputRef={field.ref}
        margin={'dense'}
        multiple={multiple}
        onBlur={field.onBlur}
        onChange={field.onChange}
        readOnly={readOnly}
        renderValue={multiple ? renderValue : undefined}
        value={field.value || defaultValue || (multiple ? [] : '')}
        variant={'outlined'}
        inputProps={{
          className: fieldClasses.input
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'top',
            horizontal: openPosition === 'top-left' ? 'left' : 'right'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: openPosition === 'top-left' ? 'left' : 'right'
          }
        }}
        fullWidth>
        {options && options.map((option, index) => (
          <MenuItem
            key={option[valueProp] as string}
            data-test-id={`${field.name}.option-${index}`}
            value={option[valueProp] as string}>
            {handleRenderOptionLabel(option)}
          </MenuItem>
        ))}
      </Select>
    </FieldBase>
  )
}
