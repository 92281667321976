import type { CaptureResult } from 'posthog-js'

export { default as PageView } from './page-view.component'

export function beforeSend(captureResult: CaptureResult | null): CaptureResult | null {
  if (process.env.NODE_ENV !== 'production') {
    console.groupCollapsed(`PostHog ${captureResult?.event}`)
    console.debug('PostHog', captureResult)
    console.groupEnd()

    return null
  }

  return captureResult
}
