import {
  BailiffsApi,
  CheckoutApi,
  CompanyApi,
  Configuration,
  CreditCasesApi,
  CreditChecksApi,
  DashboardApi,
  DebtorApi,
  OAuthApi,
  UserApi
} from './open-api'

export const API_BASE_PATH = process.env.NODE_ENV === 'development'
  ? 'http://oi.local:9500'
  : undefined

const apiConfiguration = new Configuration({
  basePath: API_BASE_PATH,
  baseOptions: {
    withCredentials: true
  }
})

export const creditCaseAPi = new CreditCasesApi(apiConfiguration)
export const creditChecksApi = new CreditChecksApi(apiConfiguration)
export const oauthApi = new OAuthApi(apiConfiguration)
export const companyApi = new CompanyApi(apiConfiguration)
export const checkoutApi = new CheckoutApi(apiConfiguration)
export const dashboardApi = new DashboardApi(apiConfiguration)
export const debtorApi = new DebtorApi(apiConfiguration)
export const userApi = new UserApi(apiConfiguration)
export const bailiffsApi = new BailiffsApi(apiConfiguration)
