import { COMPANY_GROUP_TYPE } from '@oi/constants/analytics'
import { useQuery } from '@tanstack/react-query'
import posthog from 'posthog-js'

import type { UndefinedInitialDataOptions } from '@tanstack/react-query/build/modern'

import { companyApi, oauthApi, userApi } from './api.constants'
import { unwrapResponse, useWrapMethod } from './api.internal'
import { queryClient } from './api.methods'
import {
  AuthorizeRedirect,
  Company,
  CompanyApiCompanyUpdateRequest,
  CompanyApiCompanyUploadLogoRequest,
  Me,
  OAuthApiAuthorizeRequest,
  OAuthApiLoginRequest,
  UpdateMe,
  UserApiMeUpdateRequest,
  UserApiUserForgotPasswordRequest,
  UserApiUserResetPasswordRequest
} from './open-api'

let meIdentified: string | null = null

function getMeQueryOptions(): UndefinedInitialDataOptions<Me> {
  return {
    queryKey: ['me'],
    queryFn: async () => {
      const me = await unwrapResponse(oauthApi.me())

      // Only identify ones
      if (meIdentified !== me.aId) {
        meIdentified = me.aId
        posthog.identify(
          me.aId,
          // $set
          { version: process.env.NX_PUBLIC_APP_VERSION },
          // $set_once
          { initial_version: process.env.NX_PUBLIC_APP_VERSION }
        )

        // So all future events here are linked to this company
        posthog.group(COMPANY_GROUP_TYPE, me.company.aId, {
          name: me.company.companyName,
          country: me.company.countryCode
        })
      }

      return me
    },
    // Make sure to not re-fetch it everytime the hook is used
    refetchOnMount: false
  }
}

export function getMe(): Promise<Me> {
  return queryClient.ensureQueryData(getMeQueryOptions())
}

export function useActiveUser(): Me {
  const { data } = useQuery(getMeQueryOptions())

  return data as Me
}

export function useUpdateCompanyMutation() {
  return useWrapMethod<
    CompanyApiCompanyUpdateRequest,
    Company
  >(
    (params: CompanyApiCompanyUpdateRequest) => companyApi.companyUpdate(params),
    () => ['me'],
    // We are going to update a different cache key
    <Me>(existing: Me, data: Company) => ({
      ...existing,
      company: data
    } as Me)
  )
}

export function useUploadCompanyLogoMutation() {
  return useWrapMethod<
    CompanyApiCompanyUploadLogoRequest,
    Company
  >(
    (params) => companyApi.companyUploadLogo(params),
    () => ['me'],
    // We are going to update a different cache key
    <Me>(existing: Me, data: Company) => ({
      ...existing,
      company: data
    } as Me)
  )
}

export function useLoginMutation() {
  return useWrapMethod<
    OAuthApiLoginRequest,
    void
  >((params) => oauthApi.login(params))
}

export function useLogoutMutation() {
  return useWrapMethod(async () => {
    const response = await oauthApi.logout()
    queryClient.clear()

    return response
  })
}

export function useForgotPasswordMutation() {
  return useWrapMethod<
    UserApiUserForgotPasswordRequest,
    void
  >((params) => userApi.userForgotPassword(params))
}

export function useResetPasswordMutation() {
  return useWrapMethod<
    UserApiUserResetPasswordRequest,
    void
  >((params) => userApi.userResetPassword(params))
}

export function useUpdateMeMutation() {
  return useWrapMethod<
    UserApiMeUpdateRequest,
    UpdateMe
  >(
    (params) => userApi.meUpdate(params),
    () => ['me'],
    // We are going to update a different cache key
    <Me>(existing: Me, data: UpdateMe) => ({
      ...existing,
      ...data
    } as Me)
  )
}

export function useClientApplicationFromToken(oauthToken?: string) {
  return useQuery({
    enabled: !!oauthToken,
    queryKey: ['client-application', oauthToken],
    queryFn: () => unwrapResponse(oauthApi.validateAuthorize({ oauthToken: oauthToken as string }))
  })
}

export function useAuthorizeThirdPartyTokenMutation() {
  return useWrapMethod<
    OAuthApiAuthorizeRequest,
    AuthorizeRedirect
  >((params) => oauthApi.authorize(params))
}
